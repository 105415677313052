#ace-editor {
    width: 100%!important;
    height: 100%!important;
}
.panel {
    height: 88vh;
    background-color: #272822;
    padding: 0!important;
    margin: 0!important;
}

.code {
    background-color: #272822;
}

.file {
    background-color: #272822;
    border-top: 3px solid #555651;
    position: static!important;
    overflow: scroll!important;
    padding: 0!important;
    margin: 0!important;
}

.code-editor {
    height: 100%;
    width: 100%;
}

.code-header {
    background-color: #272822;
    color: #fff!important;
}

.code-header a {
    color: #fff!important;
}

.list-group-item {
    background-color: #272822!important;
    color: #fff!important;
    border: 0!important
}

.list-group-item a {
    color: #fff;
}

.list-group-item a:hover {
    color: #555651;
    text-decoration: none;
}


.list-group {
    padding: 9%!important;
    padding-top: 15%!important;
    border: 0!important;
}

.viewed {
    color: #009688!important;
}

.control-panel {
    background-color: #555651;
    border-radius: 2px;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}

button {
    color: #fff!important;
}

.edit-button {
    margin-left: 10px;
}

.control-panel input {
    width: 31vw!important;
}

.card {
    position: absolute!important;
    bottom: 0!important;
    border-radius: 0!important;
}

.project-name {
    color: #fff;
}

.subfile {
    padding-top: 0!important;
}

.error-alert {
    position: fixed!important;
    bottom: 2vw;
    right: 4vw;
}

body {
    position: fixed;
    width: 100%;
    height: 100%;
    zoom: 100%
}

::-webkit-scrollbar {
    width: 10px;
    border-radius: '10px';
}
    
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
    
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
    /* border-radius: 5px; */
}
    
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.dropdown-menu {
    background-color: #272822 !important;
    border-radius: 10px;
}

.dropdown-menu :hover {
    background-color: #000 !important;
    border-radius: 0;
}

